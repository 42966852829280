:root {
  --black: #000;
  --white: #fff;
  --primary-color: #002173;
  --secondary-color: #f0b33f;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loadingPlayer {
  height: 0;
  width: 0;
  padding: 15px;
  border: 6px solid #fff;
  border-right-color: #CBCDCF;
  border-radius: 22px;
  animation: rotate 1s infinite linear;
  -webkit-animation: rotate 1s infinite linear;
  position: absolute;
  left: 45%;
  top: 45%;
}

.sidebar .nav .nav-item .nav-link[aria-expanded="true"] i.menu-arrow:before {
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

/* .dImgHover:hover{
  height: 200px;
  z-index: 11;
} */


/* @media (min-width: 1440px) {
  .countCards{
    min-width: 220px !important;
  }
}

@media (max-width: 1670px) {
  .countCards{
    min-width: 235px !important;
  }
}
*/
/* @media (min-width: 1780px) {
  .countCards{
    min-width: 270px !important;
  }
}  */

@keyframes rotate {

  /* 100% keyframe for  clockwise. 
     use 0% instead for anticlockwise */
  100% {
    transform: rotate(360deg);
  }
}


.dataTables_wrapper {
  padding-left: 0;
  padding-right: 0;
}
div.dataTables_wrapper div.dataTables_length select {
  width: auto;
  display: inline-block;
}

.dataTables_wrapper .dataTables_length select {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.dataTables_wrapper select {
  padding: 0.4rem;
  outline-offset: -2px;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:first-child {
  padding-left: 0;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0.85em;
  white-space: nowrap;
  font-weight: 600;
  padding-left: 28px;
  padding-bottom: 10px;
}

.dataTables_wrapper .dataTables_info {
  font-size: 0.875rem;
}

div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}

div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:last-child {
  padding-right: 0;
}

div.dataTables_wrapper div.dataTables_paginate {
  margin: 0;
  white-space: nowrap;
  text-align: right;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  margin: 2px 1px;
  white-space: nowrap;
  justify-content: flex-end;
  padding-right: 28px;
  padding-bottom: 10px;
}

.pagination,
.jsgrid .jsgrid-pager {
  display: flex;
  padding-left: 0;
  list-style: none;
}
.dataTables_wrapper .dataTables_paginate {
  margin-top: 20px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
}

.page-link {
  padding: 0.5rem 0.75rem;
  line-height: 1 !important;
  color: #007bff;
}