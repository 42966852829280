@import url("https://fonts.googleapis.com/css?family=Nunito+Sans");

.notfound_main {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Nunito Sans";
  color: var(--primary-color);
  font-size: 1em;
  height: 100vh;
}

.notfound_main button {
  font-family: "Nunito Sans";
}

.notfound_main svg {
  width: 100%;
}

.title404 {
  font-size: 7.5em;
  margin: 15px 0px;
  font-weight: bold;
}

.title404 span {
  color: var(--secondary-color);
}

.subtitle404 {
  font-weight: bold;
  letter-spacing: 1px;
}

.backToHome {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 30px;
  /* border-radius: 10px; */
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: 600;
  margin: 5px 0px;
}

.backToHome.green {
  border: 1.5px solid var(--primary-color);
  color: var(--primary-color);
}

.backToHome.green:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background: var(--secondary-color);
  z-index: -1;
  transition: 0.2s ease;
}

.backToHome.green:hover {
  color: var(--white);
  border-color: var(--secondary-color);
  background: var(--secondary-color);
  transition: 0.2s ease;
}

.backToHome.green:hover:before {
  width: 100%;
}

.notfound_main #planet,
.notfound_main #spaceman {
  animation-name: flo;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes flo {
  50% {
    transform: translateY(30px);
  }
}