body,
html {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: sans-serif;
}

root {
    display: block;
}

.v360-viewport-container {
    position: relative !important;
}

@media(max-width:500px) {
    .v360-navigate-btns button img {
        width: 25px;
    }
    .v360-viewport span img{
        width:80px;
        height:20px;
    }
}

.v360-main {
    width: 100%;
    height: 100%;
    max-width: 1024px;
    margin: 20px auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;
}

/* HEADER */

.v360-header {
    /* width: calc(100% - 20px); */
    background-color: #000;
    padding: 10px;
    color: #FFFFFF;
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
    /* text-shadow: 1px 1px #000000; */
    flex: 0 1 auto;
}

/* VIEWER */

#productInsert {
    background-color: #FFF;
    width: 100%;
    height: calc(100vh - 184px);
    max-height: 768px;
    position: relative;
    overflow: hidden;
}

.v360-fullscreen #productInsert {
    height: 100vh;
    max-height: none;
}

#viewport-wrapper {
    width: 100%;
    height: calc(100% - 10px);
    margin: 5px auto;
    position: absolute;
    left: 0;
    transition: width 0.3s ease;
}
.v360-viewport.iframe-popup {
    width: 70%!important;
    margin: 0 auto!important;
}
.v360-viewport {
    background-color: #FFF;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* position: absolute; */
    left: 0;
    /* transition: width 0.3s ease; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* Added recent below */
    border-radius: 8px;
}

#viewport-wrapper.wide {
    width: 100%;
}

.v360-viewport iframe {
    width: 100%;
    height: 100%;
    position: relative;
    /* left: 50%;
    -webkit-transform: translateX(-50%) translateY(0);
    -ms-transform: translateX(-50%) translateY(0);
    transform: translateX(-50%) translateY(0);
    transform-origin: center center; */
}

.v360-viewport img {
    position: relative;
}


/* FULLSCREEN & MENU TOGGLE BUTTONS */

.v360-fullscreen-toggle{
    width: 30px;
    height: 30px;
    margin: 15px;
    position: absolute;
    /* color: #999;
    fill: #999; */
    float: right;
    cursor: pointer;
    top: 0;
    right: 0;
    z-index: 150;
}

.v360-fullscreen-toggle:hover {
    fill: #000;
}
/* 
.v360-fullscreen-toggle div:last-child {
    display: none;
} */

.v360-fullscreen {
    z-index: 9999;
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
}

.v360-fullscreen .v360-header,
.v360-fullscreen #v360-menu-btns {
    border-radius: 0;
}

.v360-fullscreen productInsert {
    height: calc(100vh - 95px);
    max-height: none;
}

.ui-accordion-header {
    background-color: #B0BEC5;
    outline: none;
    line-height: 1.5em;
    transition: all 0.3s ease;
}

.ui-accordion-header:hover,
.ui-accordion-header.ui-state-active {
    background-color: #607D8B;
    color: #FFF;
}

/* MENU BUTTONS */

#v360-menu-btns {
    width: 100%;
    padding: 20px 0;
    text-align: center;
    /* position: absolute; */
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 150;
}

.light{
    background-color: #fff !important;
}

.dark{
    background-color: #000 !important;
}

.v360-menu-btns {
    /* background-color: #999; */
    width: auto;
    min-height: 20px;
    margin: 5px 5px;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    font-size: 1.3em;
    text-align: center;
    line-height: 1em;
    cursor: pointer;
    user-select: none;
    position: relative;
    display: inline-block;
    opacity: 1;
}
/* 
.v360-btn-active{
    background-color: #000;
} */

.light .v360-menu-btns {
    color: #000;
}

.dark .v360-menu-btns {
    color: #fff;
}
  
/* .light .v360-btn-active{
    color: #fff;
} */

.light .v360-menu-btns:hover {
    color: #fff;
    background-color: #000;
}

.dark .v360-menu-btns:hover {
    color: #000;
    background-color: #fff;
}

.v360-main {
    filter: alpha(opacity=50);
    /* opacity: 0.5; */
    cursor: default;
    /* pointer-events: none; */
}

#v360-image-container {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
}

.v360-image-container {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
}

.v360-product-box-shadow{
    position: absolute;
    /* z-index: 99; */
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    box-shadow: rgb(34, 34, 34) 0px 0px 100px inset;
}

.v360-fullscreen-toggle-btn i{
    /* background-color: #fff; */
    font-size: 20px;
}
.v360-spinner-grow{
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: rgb(33, 37, 41);
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}
.v360-percentage-description{
    margin-left: 2rem;
}


/* Tooltip */

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
  .tooltip{
    opacity: 1 !important;
  }

  /* .hotspot-button{
      position: absolute;
  } */

  /* WaterMark */
  
  .watermark {
    position: absolute;
    color: black;
  }
  
  @media (max-width: 480px) {
    .watermark {
      right: 2%;
      bottom: 8%;
      font-size: 10px;
    }
  }

  @media (max-width: 578px) {
    .watermark {
      right: 2%;
      bottom: 40% !important;
      font-size: 10px;
    }
  }

  @media (max-width: 768px) {
    .watermark {
      right: 2%;
      bottom: 44% !important;
      font-size: 12px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .watermark {
      right: 3%;
      bottom: 20%;
      font-size: 14px;
    }
  }
  
  @media (min-width: 1024px) {
    .watermark {
      right: 4%;
      bottom: 26%;
      font-size: 16px;
    }
  }