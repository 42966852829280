.login-section {
    font-family: "Lato", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    font-weight: normal;
    background: #f8f9fd;
    color: gray;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-section .form_title {
    font-size: 16px !important;
}

.diamondsOnCallTitle {
    color: var(--primary-color);
    font-weight: 600;
    letter-spacing: .1px;
    text-align: center;
}

.login-section a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    color: var(--primary-color);
    text-decoration: none;
    font-size: 14px !important;
}

.login-section a:hover,
.login-section a:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--secondary-color);
}

.heading-section {
    font-size: 28px;
    color: #000;
}

.login-section .img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../images/diamond_on_call.png");
    /* background-image: url("../images/diamonds_icon.png") */
}

.login-section .wrap {
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
}

.login-section .img,
.login-wrap {
    width: 80%;
}

@media (max-width: 991.98px) {

    .img,
    .login-wrap {
        width: 100%;
    }
}

@media (max-width: 767.98px) {
    .wrap .img {
        height: 250px;
        width: 100%;
    }
}

.login-wrap {
    position: relative;
}

.form-group {
    position: relative;
}

.form-group .label {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000;
    font-weight: 700;
    display: block;
    font-size: 11px;
    margin-bottom: 2px;
}

.forgot_password {
    margin-top: 20px;
}

.forgot_password a:hover {
    color: var(--secondary-color);
}

.signInLink {
    margin-top: 20px;
}

.form-group a {
    color: gray;
}

.form-control {
    height: 48px;
    background: #fff;
    color: #000;
    font-size: 14px;
    border-radius: 5px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #00000038;
}

.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.2) !important;
}

.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.2) !important;
}

.form-control:focus,
.form-control:active {
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid var(--secondary-color);
}

.passwordInputField {
    position: relative;
    z-index: 0;
    margin-bottom: 20px !important;
}

.passwordInputField span {

    position: absolute;
    top: 70%;
    right: 11px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.signInBtn {
    cursor: pointer;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px;
    padding: 10px 20px;
    position: relative;
}

.signInBtn:hover,
.signInBtn:active,
.btn:focus {
    outline: none;
}

.signInBtn.btn-primary {
    background: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    color: #fff !important;
    margin-top: 10px;
}

.signInBtn.btn-primary:hover {
    border: 1px solid var(--primary-color);
    background: transparent;
    color: var(--primary-color);
}

.signInBtn.btn-primary.btn-outline-primary {
    border: 1px solid var(--primary-color);
    background: transparent;
    color: var(--primary-color);
}

.signInBtn.btn-primary.btn-outline-primary:hover {
    border: 1px solid transparent;
    background: var(--primary-color);
    color: #fff;
}

.addMemberBtn {
    cursor: pointer;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px;
    padding: 7.5px 41px;
    position: relative;
}

.generateSelectedBtn {
    cursor: pointer;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px;
    padding: 8px 70px;
    position: relative;
}

.saveChangesBtn {
    cursor: pointer;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px;
    padding: 7.2px 43px;
    position: relative;
}
.resetPassBtn {
    cursor: pointer;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 15px;
    padding: 7.2px 47.5px;
    position: relative;
}

@media (max-width: 992.98px) and (min-width: 768px) {
    .login-section .img, .login-wrap {
        width: 60%;
    }
}